/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
.card-tramite {
    background-color: #fff;
    border-radius: 15px;
    border: 1px solid #cbd5e1;
}

.btn-primario {
    color: white;
    font-size: 16px;
    font-weight: 500;
    background-color: #1e293b;
    padding: .7rem 1rem;
    border-radius: 0.375rem;
    cursor: pointer;
    border: none;
    outline: none;
}

.btn-primario:hover {
    background-color: #334155;
}

.my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.dependencia-icon {
    display: inline-block;
    padding-top: .75em;
    border-radius: 8px;
    text-align: center;
}

.tag-sdare {
    border-radius: 30px;
    padding: 3px 10px;
    font-size: 12px;
    font-weight: 600;
    margin: 0em .5em 0em 0em;
}


.tag-cuadrado {
    display: inline-flex;
    padding: 3px 12px;
    font-size: 12px;
    font-weight: 600;
    align-items: center;
}

.tag-cuadrado mat-icon {
    margin-right: 0.25rem;
}

mat-label {
    font-weight: 700;
}

.icono-resultado {
    display: inline-block;
    padding: 20px 24px;
    border-radius: 120px;
    margin-bottom: 1em;
}

.preview-content-container {
    align-items: center;
    justify-content: center;
    height: 100%;
}

.preview-content-container img {
    display: block;
    margin: auto;

}

.preview-content-container pdf-viewer {
    display: block;
    margin: auto;
    width: 100vh;
    height: 75vh;
    overflow-y: hidden !important;
}

.preview-content-container .btn {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(-21%, -48%);
    -ms-transform: translate(-21%, -48%);
    background-color: #555;
    color: white;
    font-size: 16px;
    padding: 10px 10px;
    border: none;
    cursor: pointer;
    border-radius: 2px;
    text-align: center;
  }

  input[type="radio"] {
    width: 28px;
    height: 28px;

  }

  input[type="radio"]:checked {
    background-color: #3182ce;
    border-color: #3182ce;
  }

  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    color: white;
    flex-direction: column;
  }

  .clickable-div {
    cursor: pointer;
  }

.scroll-modal {
    max-height: 500px;
    overflow-y: auto;
}

.example-accordion {
    display: block;
}

.example-accordion-item {
    display: block;
    border: solid 1px #ccc;
}

.example-accordion-item+.example-accordion-item {
    border-top: none;
}

.example-accordion-item-header {
    display: flex;
    align-content: center;
    justify-content: space-between;
}

.example-accordion-item-description {
    font-size: 0.85em;
    color: #999;
}

.example-accordion-item-header {
    padding: 16px;
}

.example-accordion-item-body {
    padding: 0px 16px 16px 16px;
}

.example-accordion-item-header:hover {
    cursor: pointer;
    background-color: #eee;
}

.example-accordion-item:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.example-accordion-item:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.solicitudes-pill.active {
    background-color: #ffffff;
    cursor: pointer;
    color: #1e293b;
}

.solicitudes-pill {
    border-width: 1px 1px 0 1px;
    border-style: solid;
    border-color: #cbd5e1;
    padding: 0.75rem 1.25rem;
    display: inline-flex;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    cursor: pointer;
    font-weight: 600;
    color: #94a3b8;
}

.solicitudes-pill:hover {
    background-color: #e2e8f0;
}

.solicitudes-pill.active:hover {
    background-color: #f8fafc;
}

@media (max-width: 767px) {
    .solicitudes-pill {
        padding: 0.5rem 1rem;
    }
}

/* Estilos para el select input */
.solicitudes-select {
    width: 100%;
    max-width: 100%;
    border: 1px solid #cbd5e1;
    background-color: #ffffff;
    color: #1e293b;
    font-weight: 600;
    cursor: pointer;
}

.pointer {
    cursor: pointer;
 }

 .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    //z-index: 9999; 
  }

  .spinner-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .spinner-content p {
    margin-top: 16px;
    font-size: 16px;
    color: #000; /* Cambia el color del texto si es necesario */
  }